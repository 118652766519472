var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isMobile())?_c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.users,"options":_vm.pagination,"server-items-length":_vm.totalUsers,"loading":_vm.loading,"footer-props":{
      showFirstLastPage: true,
      'items-per-page-options': [10, 20, 50, 100]
    }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.sendEmail",fn:function(ref){
    var item = ref.item;
return [_c('v-dialog',{attrs:{"persistent":"","max-width":"700px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"indigo","size":"28","rounded":"","icon":""},on:{"click":function($event){return _vm.showSendEmailDialog(item)}}},on),[_c('v-icon',[_vm._v("mdi-send")])],1)]}}],null,true),model:{value:(item.sendEmailDialog),callback:function ($$v) {_vm.$set(item, "sendEmailDialog", $$v)},expression:"item.sendEmailDialog"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"700px"}},[_c('v-card-title',{staticClass:"text-center"},[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t('pages.influencers.sendEmail')))])]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Subject"},model:{value:(item.subject),callback:function ($$v) {_vm.$set(item, "subject", $$v)},expression:"item.subject"}}),_c('v-textarea',{attrs:{"rows":"6","row-height":"30","label":"Message"},model:{value:(item.body),callback:function ($$v) {_vm.$set(item, "body", $$v)},expression:"item.body"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.hideSendEmailDialog(item)}}},[_vm._v(_vm._s(_vm.$t('common.cancel')))]),_c('v-btn',{staticClass:"send-btn",attrs:{"text":"","loading":item.isSubmitting},on:{"click":function($event){return _vm.submitSendEmail(item)}}},[_vm._v(" "+_vm._s(_vm.$t('common.send'))+" ")])],1),(item.isSubmitting)?_c('v-progress-linear',{staticClass:"my-4",attrs:{"color":"primary","indeterminate":""}}):_vm._e()],1)],1)]}},{key:"item.username",fn:function(ref){
    var item = ref.item;
return [_c('td',{staticClass:"text-xs-right"},[_vm._v(" "+_vm._s(item.userProfiles != null && item.userProfiles.length > 0 ? item.userProfiles[0].fullName : '')+" ")])]}},{key:"item.gender",fn:function(ref){
    var item = ref.item;
return [_c('td',{staticClass:"text-xs-right"},[_vm._v(" "+_vm._s(item.userProfiles != null && item.userProfiles.length > 0 ? item.userProfiles[0].gender === 'U' ? _vm.$t('gender.unisex') : item.userProfiles[0].gender === 'M' ? _vm.$t('gender.male') : _vm.$t('gender.female') : '')+" ")])]}},{key:"item.email",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.email)+" ")]}},{key:"item.requestedDate",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.editDate(item.influencerRequestDate))+" ")]}},{key:"item.influencerStatus",fn:function(ref){
    var item = ref.item;
return [_c('td',{staticClass:"text-xs-right"},[(item.userProfiles != null && item.userProfiles.length > 0)?_c('v-select',{attrs:{"items":_vm.allInfluencerStatuses,"item-text":"customText","item-value":"value","return-object":"","single-line":"","disabled":_vm.loading},on:{"change":function($event){return _vm.changeStatus(item)}},model:{value:(item.influencerStatus),callback:function ($$v) {_vm.$set(item, "influencerStatus", $$v)},expression:"item.influencerStatus"}}):_vm._e()],1)]}},{key:"item.approveInfluencer",fn:function(ref){
    var item = ref.item;
return [_c('v-switch',{on:{"click":function($event){return _vm.toggleUserInfluencer(item)}},model:{value:(item.influencer),callback:function ($$v) {_vm.$set(item, "influencer", $$v)},expression:"item.influencer"}})]}},{key:"body.prepend",fn:function(){return [_c('tr',[_c('td',[_c('v-text-field',{attrs:{"type":"text"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.page = 1;
              _vm.retrieveUsers();}},model:{value:(_vm.userId),callback:function ($$v) {_vm.userId=$$v},expression:"userId"}})],1),_c('td',[_c('v-text-field',{attrs:{"type":"text"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.page = 1;
              _vm.retrieveUsers();}},model:{value:(_vm.userName),callback:function ($$v) {_vm.userName=$$v},expression:"userName"}})],1),_c('td',[_c('v-select',{attrs:{"dense":"","hide-details":"","items":_vm.gendersFilter,"item-text":"text","item-value":"value"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.page = 1;
              _vm.retrieveUsers();}},model:{value:(_vm.gender),callback:function ($$v) {_vm.gender=$$v},expression:"gender"}})],1),_c('td',[_c('v-text-field',{attrs:{"type":"text"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.page = 1;
              _vm.retrieveUsers();}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('td',[_c('v-datetime-picker',{attrs:{"label":_vm.$t('date.from'),"time-format":"HH:mm:ss"},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}})],1),_c('td',[_c('v-select',{attrs:{"items":_vm.allInfluencerStatuses,"item-text":"customText","item-value":"value","dense":"","hide-details":"","clearable":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.page = 1;
              _vm.retrieveUsers();}},model:{value:(_vm.influencerStatus),callback:function ($$v) {_vm.influencerStatus=$$v},expression:"influencerStatus"}})],1),_c('td',[_c('v-select',{attrs:{"items":_vm.userInfluencerList,"item-text":"text","item-value":"value","dense":"","hide-details":"","clearable":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.page = 1;
              _vm.retrieveUsers();}},model:{value:(_vm.influencer),callback:function ($$v) {_vm.influencer=$$v},expression:"influencer"}})],1),_c('td',[_c('v-avatar',{attrs:{"color":"indigo","size":"30"}},[_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){_vm.page = 1;
                _vm.retrieveUsers();}}},[_vm._v("mdi-magnify ")])],1)],1)])]},proxy:true}],null,false,3225078987)})],1):(_vm.isMobile())?_c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersMobile,"items":_vm.allInfluencers,"options":_vm.pagination,"server-items-length":_vm.totalUsers,"loading":_vm.loading,"footer-props":{
      showFirstLastPage: true,
      'items-per-page-options': [10, 20, 50, 100]
    }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.username",fn:function(ref){
    var item = ref.item;
return [_c('td',{staticClass:"text-xs-right"},[_vm._v(" "+_vm._s(item.userProfiles != null && item.userProfiles.length > 0 ? item.userProfiles[0].fullName : '')+" ")])]}},{key:"item.gender",fn:function(ref){
    var item = ref.item;
return [_c('td',{staticClass:"text-xs-right"},[_vm._v(" "+_vm._s(item.userProfiles != null && item.userProfiles.length > 0 ? item.userProfiles[0].gender === 'U' ? _vm.$t('gender.unisex') : item.userProfiles[0].gender === 'M' ? _vm.$t('gender.male') : _vm.$t('gender.female') : '')+" ")])]}},{key:"item.email",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.email)+" ")]}},{key:"item.approveInfluencer",fn:function(ref){
    var item = ref.item;
return [_c('v-switch',{on:{"click":function($event){return _vm.toggleUserInfluencer(item)}},model:{value:(item.influencer),callback:function ($$v) {_vm.$set(item, "influencer", $$v)},expression:"item.influencer"}})]}},{key:"body.prepend",fn:function(){return [_c('div',{staticClass:"filter"},[_c('div',[_c('v-text-field',{attrs:{"type":"text","label":"username"},model:{value:(_vm.userName),callback:function ($$v) {_vm.userName=$$v},expression:"userName"}})],1),_c('div',[_c('v-text-field',{attrs:{"type":"text","label":"email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('div',{staticClass:"search"},[_c('v-row',{staticClass:"custom-row",staticStyle:{"align-items":"center !important"}},[_c('v-col',{staticStyle:{"padding-right":"0px","padding-left":"0px","max-width":"24px","margin-right":"10px"}},[_c('v-avatar',{attrs:{"color":"indigo","size":"30"}},[_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){_vm.page = 1;
                    _vm.retrieveUsers();}}},[_vm._v("mdi-magnify ")])],1)],1),_c('v-col',{staticStyle:{"padding-right":"0px","padding-left":"0px","max-width":"24px","margin-right":"10px"}},[_c('v-avatar',{staticClass:"xico",attrs:{"color":"grey","size":"24"}},[_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){_vm.page = 1;
                    _vm.clearSearch();}}},[_vm._v("mdi-close ")])],1)],1)],1)],1)])]},proxy:true}])})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }