import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import UserApiService from '@/core/services/api.service.user';

export default {
  props: {
    modal: {
      default: false
    },
    value: Boolean
  },
  name: 'influencer-list',
  data() {
    return {
      sendEmailDialogErrors: [],
      gender: '',
      genderChanging: false,
      pageSizes: [10, 20, 50, 100],
      userName: '',
      userId: '',
      email: '',
      influencerStatus: '',
      // influencerStat: "",
      dateFrom: null,
      dateTo: null,
      dates: [],
      dateRangeMenu: false,
      userCities: [],
      citiesIsLoading: false,
      shopName: '',
      search: '',
      influencer: '',
      influencerStatuses: ['REQUEST_SENT', 'EMAIL_SENT', 'APPROVED', 'UNDER_REVIEW', 'REJECTED'],
      totalUsers: 0,
      users: [],
      allUsers: [],
      loading: false,
      pagination: {},
      dialogTest: false,
      showProfileDialog: false,
      userProfile: null,
      notifications: false,
      sound: true,
      widgets: false,
      loader: null,
      userRoleList: ['All', 'Member', 'Administrator', 'Superadmin', 'Partner'],
      errors: [],
      influencerTF: null,
      status: ''
    };
  },
  computed: {
    name() {
      return this.$i18n.t('menu.influencers');
    },
    headersMobile() {
      return [
        {
          text: 'Id',
          align: 'start',
          value: 'id',
          width: '50%'
        },
        { text: this.$i18n.t('common.user'), value: 'username', sortable: false },
        {
          text: this.$i18n.t('pages.users.gender'),
          value: 'gender',
          sortable: false,
          width: '10%'
        },
        { text: this.$i18n.t('common.email'), value: 'email', sortable: false },
        {
          text: this.$i18n.t('pages.influencers.approveInfluencer'),
          value: 'approveInfluencer',
          width: '15%',
          sortable: false
        }
        // { text: this.$i18n.t('pages.influencers.sendEmail'), value: "sendEmail", width: "15%", sortable: false },
      ];
    },
    headers() {
      return [
        { text: 'ID', align: 'start', value: 'id', width: '5%' },
        { text: this.$i18n.t('common.user'), value: 'username', width: '15%', sortable: false },
        {
          text: this.$i18n.t('pages.users.gender'),
          value: 'gender',
          sortable: false,
          width: '10%'
        },
        { text: this.$i18n.t('common.email'), value: 'email', sortable: false, width: '15%' },
        {
          text: this.$i18n.t('pages.influencers.requestedDate'),
          value: 'requestedDate',
          width: '15%'
        },
        {
          text: this.$i18n.t('commonTable.status'),
          value: 'influencerStatus',
          width: '15%',
          sortable: false
        },
        {
          text: this.$i18n.t('pages.influencers.approveInfluencer'),
          value: 'approveInfluencer',
          width: '15%',
          sortable: false
        },
        {
          text: this.$i18n.t('pages.influencers.sendEmail'),
          value: 'sendEmail',
          width: '15%',
          sortable: false
        }
      ];
    },

    gendersFilter() {
      return [
        {
          text: this.$i18n.t('gender.all'),
          value: 'All'
        },
        {
          text: this.$i18n.t('pages.users.profile'),
          value: 'profile',
          width: '5%',
          sortable: false
        }
      ];
    },
    genders() {
      return [
        {
          text: this.$i18n.t('gender.male'),
          value: 'M'
        },
        {
          text: this.$i18n.t('gender.female'),
          value: 'F'
        },
        {
          text: this.$i18n.t('gender.unisex'),
          value: 'U'
        }
      ];
    },
    allInfluencerStatuses() {
      return [
        {
          customText: this.$i18n.t('influencerStatuses.requestSent'),
          value: 'REQUEST_SENT'
        },
        {
          customText: this.$i18n.t('influencerStatuses.emailSent'),
          value: 'EMAIL_SENT'
        },
        {
          customText: this.$i18n.t('influencerStatuses.underReview'),
          value: 'UNDER_REVIEW'
        },
        {
          customText: this.$i18n.t('influencerStatuses.rejected'),
          value: 'REJECTED'
        },
        {
          customText: this.$i18n.t('influencerStatuses.active'),
          value: 'APPROVED'
        }
      ];
    },
    userInfluencerList() {
      return [
        {
          text: this.$i18n.t('common.all'),
          value: undefined
        },
        {
          text: this.$i18n.t('common.no'),
          value: 'No'
        },
        {
          text: this.$i18n.t('common.yes'),
          value: 'Yes'
        }
      ];
    }
  },
  watch: {
    pagination: {
      handler() {
        this.retrieveUsers();
      },
      deep: true
    }
  },
  created() {
    //console.log("async created");
    UserApiService.init();
  },
  methods: {
    /* eslint-disable */
    isMobile() {
      var check = false;
      (function(a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },

    toggleUserInfluencer(item) {
      this.loading = true;
      if (item.influencer !== true) {
        this.status = 'REQUEST_SENT';
        this.influencerTF = false;
      } else {
        this.status = 'APPROVED';
        this.influencerTF = true;
      }
      console.log(item);
      ApiService.patchInf(`api/users/${item.id}`, {
        influencer: this.influencerTF,
        influencerStatus: this.status
      })
        .then(response => {
          this.$log.debug('Influencer status: ', response.data.influencer);
          this.info = response.data.influencer;
          this.retrieveUsers();
        })
        .catch(error => {
          this.$log.error('Error: ', error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },

    changeStatus(item) {
      this.loading = true;
      this.influencerTF = item.influencerStatus.value === 'APPROVED' ? true : false;
      console.log(item.influencerStatus);
      ApiService.patchInf(`api/users/${item.id}`, {
        influencer: this.influencerTF,
        influencerStatus: item.influencerStatus.value
      })
        .then(response => {
          this.$log.debug(
            'Influencer status: ',
            response.data.influencerStatus,
            response.data.influencer
          );
          this.info = response.data.influencerStatus;
          this.retrieveUsers();
        })
        .catch(error => {
          this.$log.error('Error: ', error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
      console.log(item);
    },
    editDate(date) {
      if (!date) return '';
      const parsedDate = new Date(date);
      const months = [
        this.$i18n.t('month.jan'),
        this.$i18n.t('month.feb'),
        this.$i18n.t('month.mar'),
        this.$i18n.t('month.apr'),
        this.$i18n.t('month.may'),
        this.$i18n.t('month.jun'),
        this.$i18n.t('month.jul'),
        this.$i18n.t('month.aug'),
        this.$i18n.t('month.sep'),
        this.$i18n.t('month.oct'),
        this.$i18n.t('month.nov'),
        this.$i18n.t('month.dec')
      ];

      const month = months[parsedDate.getMonth()];
      const day = parsedDate.getDate();
      const year = parsedDate.getFullYear();

      return ` ${month} ${day} ${year}`;
    },

    getRequestParams(
      userName,
      gender,
      email,
      dateFrom,
      dateTo,
      influencer,
      userId,
      influencerStatus
    ) {
      let params = {};

      if (userName) {
        params['username'] = userName;
      }

      if (gender) {
        params['gender'] = gender;
      }

      if (email) {
        params['email'] = email;
      }

      if (dateFrom) {
        params['dateFrom'] = dateFrom;
      }

      if (dateTo) {
        params['dateTo'] = dateTo;
      }

      if (influencer) {
        params['userInfluencer'] = influencer;
      }

      if (userId) {
        params['userId'] = userId;
      }

      let sort;
      let sortBy = this.pagination.sortBy;
      let sortDesc = this.pagination.sortDesc;
      if (sortBy.length === 1 && sortDesc.length === 1) {
        sort = sortBy[0];
        if (sortDesc[0] === true) sort += ',desc';
      }
      params['sort'] = sort;
      params['page'] = this.pagination.page;
      params['size'] = this.pagination.itemsPerPage;
      params['influencerStatuses'] = this.influencerStatuses.join(',');

      if (influencerStatus) {
        params['influencerStatuses'] = influencerStatus;
      }

      return params;
    },
    clearSearch() {
      (this.userName = ''),
        (this.gender = ''),
        (this.email = ''),
        (this.dateFrom = null),
        (this.dateTo = null),
        (this.influencer = ''),
        (this.influencerStatus = ''),
        (this.userId = '');
    },
    retrieveUsers() {
      const params = this.getRequestParams(
        this.userName,
        this.gender,
        this.email,
        this.dateFrom,
        this.dateTo,
        this.influencer,
        this.userId,
        this.influencerStatus
      );

      return new Promise(resolve => {
        this.loading = true;
        ApiService.query('api/users', {
          params
        }).then(response => {
          //  this.$log.debug("Users influencer response: ", response.data);
          this.users = response.data.content;
          console.log(this.users);
          this.influencers = this.users;
          // this.$log.debug("Users influencer response: ", this.allUsers);
          this.totalUsers = this.influencers.length;
          this.totalPages = response.data.totalPages;
          this.loading = false;
          let title = this.totalUsers;
          this.$store.dispatch(SET_BREADCRUMB, [{ title: `${this.name}: ` + title }]);
          resolve();
        });
      });
    },

    showSendEmailDialog(user) {
      this.sendEmailDialogErrors = [];
      console.log('Show send email dialog for ' + user.email);
      this.passwordMask = true;
      user.subject = '';
      user.body = '';
    },
    hideSendEmailDialog(user) {
      console.log('Hide send email dialog for' + user.email);
      user.sendEmailDialog = false;
    },
    submitSendEmail(user) {
      this.loading = true;

      const requestData = {
        subject: user.subject,
        body: user.body
      };

      if (user.id != null) {
        ApiService.post(`api/users/${user.id}/email`, requestData)
          // Vue.axios.post(`https://admin-panel-cloud-test.ooblee.me/api/users/${user.id}/email`, requestData)
          .then(response => {
            this.$log.debug('Email sent: ' + response);
          })
          .catch(error => {
            this.$log.error('Error: ', error);
            this.errored = true;
          })
          .finally(() => {
            this.loading = false;
          });
        user.sendEmailDialog = false;
      }
    }
  }
};
