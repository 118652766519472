import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

/**
 * Service to call HTTP request via Axios
 */
const UserApiService = {
  URL: "",
  async init() {
    Vue.use(VueAxios, axios);
    const resp = await Vue.axios.get(`api/url/get-user-service-uri`);
    this.URL = resp.data;
  },

  query(resource, params) {
    let config = {
      params
    };

    return Vue.axios.get(this.URL + resource, config).catch(error => {
      // console.log(error);
      throw new Error(`[KT] UserApiService ${error}`);
    });
  },

  API_CATEGORIES: "/admin/categories",

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  async get(resource, slug = "") {
    return Vue.axios.get(this.URL + `${resource}/${slug}`).catch(error => {
      throw new Error(`[KT] UserApiService ${error}`);
    });
  },

  getFile(resource, slug = "") {
    return Vue.axios
      .get(this.URL + `${resource}/${slug}`, {
        responseType: "blob"
      })
      .catch(error => {
        throw new Error(`[KT] UserApiService ${error}`);
      });
  },
  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */ post(resource, params) {
    return Vue.axios.post(this.URL + `${resource}`, params);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(this.URL + `${resource}/${slug}`, params);
  },

  patch(resource, params) {
    return Vue.axios.patch(this.URL + `${resource}`, params, {
      headers: { "Content-Type": "application/json-patch+json" }
    });
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(this.URL + `${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return Vue.axios.delete(this.URL + resource).catch(error => {
      // console.log(error);
      throw new Error(`[RWV] UserApiService ${error}`);
    });
  },
  async getCategories() {
    try {
      return await Vue.axios.get(this.URL + this.API_CATEGORIES);
    } catch (error) {
      this.$log.error("Error: ", error);
      throw new Error(`[RWV] UserApiService ${error}`);
    }
  }
};

export default UserApiService;
